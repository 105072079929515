import React from "react"
import { Layout } from "../components/Layout"
import {
  CardInfo,
  SectionDivider,
  SectionDividerMobile,
  ServiceSection,
  DemoButton,
  GetStartedButton,
  LearnMoreButton,
  CountsSection,
  Faq
} from "../commonComponents"
import { Helmet } from "react-helmet"

export default function AISentimentAnalysis() {
  return (
    <Layout
      keywords="ai sentiment analysis, customer sentiment, ticket sentiment, Zendesk ticket sentiment"
      title="Unlock Customer Insights: AI Sentiment Analysis for Proactive Support"
      description="Elevate your customer support strategy with AI Sentiment Analysis. Gain deep insights into customer experiences and proactively manage support tickets based on emotion and language cues. Enhance customer satisfaction with advanced CX analytics."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/ai-sentiment-analysis/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top section-padding-right"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              <span style={{ color: "#EF5DA8", fontWeight: 600 }}>CX Insights and Visibility</span>
              <br />
               with AI Sentiment Analysis
            </h1>
            <div className="description-support-new-design web-view-new-design-display">
              Get actionable customer sentiment and unlock the voice <br/>
              of the customer. Automate tagging, intents, and<br/>
              perform sentiment analysis for every support ticket. <br/>
              Capture and analyze customer feedback using our <br/>
              centralized sentiment analysis system.
            </div>
            <div className="description-support-new-design mobile-view-new-design-display">
              Get actionable customer sentiment <br/>
              and unlock the voice of the<br/>
              customer. Automate tagging, intents,<br/>
              and sentiment for every support ticket.
            </div>
            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6" style={{ padding: 0 }}>
            <div className="ai-sentiment-background"></div>
          </div>
        </div>
      </section>

      {/* <!-- ======= About Auto-detected incidents Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-right"
        style={{ marginBottom: "5%", marginLeft: "4%", marginTop: "3%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#7879F1"></SectionDividerMobile>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/img/sentiment.webp"
                style={{
                  width: "100%"
                }}
                alt="about section"
              />
            </div>
            <SectionDivider color="#7879F1"></SectionDivider>
            <div
              className="auto-detect-info-container-50"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="auto-detect-subcontainer-mobile">
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/sentiment.webp"
                    alt="about section"
                    style={{ width: "88%", paddingBottom: "10%" }}
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title agent-assistant-auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  Emotion and sentiment analysis tools for<br/> every interaction
                    </h2>
                  <div className="generic-text"
                  style={{ marginBottom: "5%" }}>
                    Our AI Sentiment Analysis solution provides instant visibility into the emotional tone of customer interactions. Leveraging advanced natural language processing algorithms, machine learning, and opinion mining, our solution can identify positive or negative sentiment as they unfold, allowing your support team to respond promptly to customer needs. It adeptly identifies positive and negative sentiments in text data from customer interactions, offering insights that can drive product improvements and enhance customer satisfaction.
                  </div>
                <div style={{ display: "flex" }}>
                  <DemoButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Auto-detected incidents Section --> */}
      {/* <!-- ======= About escalations Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top section-padding-left-mobile section-padding-right"
        style={{ marginBottom: "5%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#EF5DA8"></SectionDividerMobile>
            <div
              className="auto-detect-info-container-40"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/tagging.webp"
                    style={{
                      width: "90%",
                      paddingBottom: "10%",
                    }}
                    alt="about section"
                  />
                </div>
                <div
                  className="section-title-new-design auto-detect-title"
                  style={{ marginBottom: "5%" }}
                >
                  <h2
                    className="section-title-new-design auto-detect-title"
                    style={{ marginBottom: "5%" }}
                  >
                      Automated ticket tagging and prioritization
                    </h2>
                    <div className="generic-text"
                    style={{ marginBottom: "5%" }}
                    >
                      Highly accurate product tags and intents are applied to every ticket automatically. Get rich reporting and analytics on top problem areas and reasons of contact. Drill down reports by sentiment, CSAT, account, revenue, and many more dimensions.
                    </div>

                </div>
                <div style={{ display: "flex" }}>
                  <GetStartedButton />
                </div>
              </div>
            </div>
            <SectionDivider color="#EF5DA8" flipped={true}></SectionDivider>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "50%",
                display: "flex",
                alignItems: "baseline",
                justifyContent: "flex-start",
              }}
            >
              <img
                src="/img/tagging.webp"
                style={{
                  width: "80%",
                }}
                alt="about section"
              />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End About Similar Cases Section --> */}
      {/* <!-- ======= About Auto-detected incidents Section ======= --> */}
      <section
        id="about"
        className="about auto-detect-section auto-detect-section-less-top auto-detect-section-less-bottom section-padding-right"
        style={{ marginLeft: "4%", marginBottom: "5%" }}
      >
        <div className="row">
          <div className="col-md-12" style={{ display: "flex", width: "100%" }}>
            <SectionDividerMobile color="#9B51E0"></SectionDividerMobile>
            <div
              className="auto-detect-img-not-mobile"
              style={{
                width: "40%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/img/proactiveSupport3.webp"
                style={{
                  width: "90%",
                }}
                alt="about section"
              />
            </div>
            <SectionDivider color="#43CB9B"></SectionDivider>
            <div
              className="auto-detect-info-container-50"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <div className="auto-detect-subcontainer-mobile">
                <div className="mobile-view-new-design-display">
                  <img
                    src="/img/proactiveSupport3.webp"
                    style={{
                      width: "90%",
                      marginLeft: "-7%",
                      marginTop: "-8%",
                    }}
                    alt="about section"
                  />
                </div>
                <h2
                  className="section-title-new-design auto-detect-title agent-assist-auto-detect-margin-top"
                  style={{ marginBottom: "5%" }}
                >

                  Customizable Alerts and Proactive Notifications

                </h2>
                  <div className="generic-text"
                  style={{ marginBottom: "5%" }}
                  >
                   Our AI Sentiment Analysis solution provides customizable alerts and notifications for critical sentiment shifts or escalating issues. With our sentiment analysis tool, you can tailor notifications to suit your business priorities, ensuring you never miss an opportunity to turn a negative experience into a positive one. In-built customization of alert thresholds based on the severity of sentiments in support tickets.
                  </div>
                <div style={{ display: "flex" }}>
                  <DemoButton />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}
        <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How do AI Sentiment Analysis tools enhance customer support?"
                  answer="Sentiment analysis tools enhance customer support by enabling businesses to analyze large volumes of customer interactions across social medial platforms, such as emails, chat transcripts, and social media posts, in real-time. These tools automatically detect the sentiment expressed in these interactions, whether positive, negative, or neutral, allowing support teams to prioritize and address customer issues more effectively. By identifying negative sentiments early, businesses can proactively intervene to resolve customer concerns, prevent escalations, and improve overall customer satisfaction with sentiment analysis system. Additionally, sentiment analysis provides valuable insights into customer preferences, pain points, and trends across social media platforms, enabling businesses to tailor their support strategies and product offerings accordingly, leading to enhanced customer experiences and loyalty."
                />
                <Faq
                  id="faq2"
                  question="Why is reducing reliance on CSAT surveys important in lieu of sentiment score?"
                  answer="Reducing reliance on CSAT (Customer Satisfaction) surveys in favor of sentiment scores is important because sentiment scores offer real-time insights into customer satisfaction levels without relying on post-interaction surveys. While CSAT surveys provide valuable customer feedback, they are often limited by low response rates, bias, and delayed feedback. In contrast, sentiment scores derived from AI analysis offer immediate feedback by analyzing customer interactions, such as emails, chats, and social media posts, allowing businesses to promptly address issues and improve customer experiences. By leveraging sentiment scores, businesses can gain a more comprehensive understanding of customer sentiment across various touchpoints, enabling them to proactively enhance satisfaction levels and drive long-term loyalty."
                />
                <Faq
                  id="faq3"
                  question="How do the sentiment analysis algorithms predict escalations in support tickets?"
                  answer="Sentiment analysis models and algorithms predict escalations in support tickets by data mining and analyzing the language and tone used in customer interactions to identify indicators of dissatisfaction or frustration. These sentiment analysis model algorithms can detect patterns such as sentiment, urgency, or escalation cues within the text of support tickets. By continuously monitoring and analyzing these cues, the algorithms can accurately predict which support tickets are more likely to escalate into significant issues or complaints. This allows support teams to prioritize and intervene in critical cases promptly, preventing potential escalations and resolving customer concerns proactively."
                />
                <Faq
                  id="faq4"
                  question="What is generative AI sentiment analysis?"
                  answer="Generative AI-powered sentiment analysis involves employing artificial intelligence algorithms to analyze text data and generate expressive content that reflects sentiments, emotions, or opinions conveyed within the input text. Unlike traditional sentiment analysis methods that classify text into predefined categories (positive, negative, neutral), generative AI goes a step further by creating new text that captures the nuances and complexities of human emotions. This approach allows for a deeper understanding of the underlying types of sentiment analysis and enables more nuanced responses or insights."
                />
                <Faq
                  id="faq5"
                  question="Can Sentiment Analysis be integrated with existing support systems?"
                  answer="Yes, the solution seamlessly integrates with existing customer support systems and ticketing platforms, enhancing text analytics based sentiment analysis capabilities without disrupting established workflows. It can power sentiment analysis within Zendesk, Salesforce, Intercom, Freshworks, and many others."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}

      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
